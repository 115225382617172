.recharts-cartesian-grid-horizontal {
  line {
    stroke-dasharray: 2, 2;
    stroke: #d0d0da;
  }
}

.recharts-cartesian-axis-tick-value {
  tspan {
    fill: #a1a1b5;
    letter-spacing: 0.03em;
    // font-weight: bold;
    font-size: 14px;
  }
}

.recharts-cartesian-axis.recharts-yAxis {
  .recharts-cartesian-axis-tick {
  }
}

.recharts-tooltip-cursor {
  fill: rgba(204, 204, 204, 0.2);
}

@btn-primary-bg: #167FC3;@text-color: #000;@menu-highlight-color: #000;@primary-color: #167FC3;@font-size-base: 12px;@font-family: 'Lato', sans-serif;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@form-item-margin-bottom: 14px;@form-vertical-label-padding:  0 0 6px;@border-radius-base: 4px;@border-radius-sm: 4px;