.antd-table-day{
    & thead > tr > th {
        background: #fafafa;
    }
}

.antd-table-session{
    & thead > tr > th {
        background: #bebebe;
    }
}

.antd-table-agenda{
    & thead > tr > th {
        background: #a5a3a3;
    }
}

.antd-table-workshop{
    & thead > tr > th {
        background: #8f8d8d;
    }
}

@btn-primary-bg: #167FC3;@text-color: #000;@menu-highlight-color: #000;@primary-color: #167FC3;@font-size-base: 12px;@font-family: 'Lato', sans-serif;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@form-item-margin-bottom: 14px;@form-vertical-label-padding:  0 0 6px;@border-radius-base: 4px;@border-radius-sm: 4px;