//ref: https://www.freecodecamp.org/news/css-media-queries-breakpoints-media-types-standard-resolutions-and-more/
@extraLarge: ~'only screen and (min-width: 1201px)';
@desktop: ~'only screen and (min-width: 1025px) and (max-width: 1200px)';
@smallDesktop: ~'only screen and (min-width: 769px) and (max-width: 1024px)';
@tablet: ~'only screen and (min-width: 481px) and (max-width: 768px)';
@mobile: ~'only screen and (min-width: 320px) and (max-width: 480px)';

.main-banner {
  .banner-user {
    height: 500px;
  }
  .banner-user-elem {
    color: #fff;
    position: relative;
    overflow: hidden;
  }
  .banner-content {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: left;
    padding-left: 10%;
    .content-description {
      width: 50%;
      padding: 30px;
      background: rgba(0, 0, 0, 0.4);
    }
  }
  .banner-user-elem .banner-user-title {
    font-size: 40px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    // font-family: 'Open Sans', sans-serif;
    font-family: 'Roboto', sans-serif !important;
  }
  .banner-user-elem .banner-user-text {
    // top: 40%;
    font-size: 12px;
    margin-left: 10px;
  }
  .banner-anim-elem .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }
  .background-image {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
    overflow: hidden;

    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
  }
}

@media @extraLarge {
}
@media @desktop {
}
@media @smallDesktop {
}
@media @tablet {
}
@media @mobile {
  .main-banner {
    .banner-user-elem .banner-user-title {
      font-size: 30px;
    }

    .banner-content {
      padding-left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .content-description {
        width: 100% !important;
      }
    }
  }
}

@btn-primary-bg: #167FC3;@text-color: #000;@menu-highlight-color: #000;@primary-color: #167FC3;@font-size-base: 12px;@font-family: 'Lato', sans-serif;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@form-item-margin-bottom: 14px;@form-vertical-label-padding:  0 0 6px;@border-radius-base: 4px;@border-radius-sm: 4px;