//ref: https://www.freecodecamp.org/news/css-media-queries-breakpoints-media-types-standard-resolutions-and-more/
@extraLarge: ~'only screen and (min-width: 1201px)';
@desktop: ~'only screen and (min-width: 1025px) and (max-width: 1200px)';
@smallDesktop: ~'only screen and (min-width: 769px) and (max-width: 1024px)';
@tablet: ~'only screen and (min-width: 481px) and (max-width: 768px)';
@mobile: ~'only screen and (min-width: 320px) and (max-width: 480px)';

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  padding: 0 15px !important;
}

.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 30px !important;
  line-height: 30px !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid #fa8c15 !important;
}

.app-header {
  top: 0;
  z-index: 99;
  position: sticky;
  position: -webkit-sticky;
  border-bottom: 1px solid #f0f0f0;

  box-shadow: -3px -8px 27px -8px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -3px -8px 27px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -3px -8px 27px -8px rgba(0, 0, 0, 0.75);

  .header {
    height: 70px;
    // background-color: #1360ef;
    background-color: #1b1b1b;
    border-bottom: 1px solid #f0f0f0;
    display: flex;

    .header-logo {
      //   background: red;
      display: flex;
      height: 100%;
      align-items: center;
    }

    .content-right {
      margin-left: auto;
    }
    .account-content {
      // background: red;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
    }
  }
  .desktop-menu {
    .ant-menu-overflow {
      justify-content: center;
    }
  }
  .mobile-menu {
    display: none;
  }
  .header-search {
    margin-left: auto !important;
    // background: rebeccapurple;
    // margin-left: 20px;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none !important;
}

@media @extraLarge {
  .app-header {
    .mobile-menu {
      display: none;
    }
  }
}
@media @desktop {
  .app-header {
    .mobile-menu {
      display: none;
    }
  }
}
@media @smallDesktop {
  .app-header {
    .mobile-menu {
      display: none;
    }
  }
}
@media @tablet {
  .app-header {
    .mobile-menu {
      display: none;
    }
  }
}

@media @mobile {
  .app-header {
    .desktop-menu {
      display: none;
    }
    .mobile-menu {
      display: block;
    }
    .header {
      .header-logo {
        display: none;
      }
    }
  }
}

@btn-primary-bg: #167FC3;@text-color: #000;@menu-highlight-color: #000;@primary-color: #167FC3;@font-size-base: 12px;@font-family: 'Lato', sans-serif;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@form-item-margin-bottom: 14px;@form-vertical-label-padding:  0 0 6px;@border-radius-base: 4px;@border-radius-sm: 4px;