.container-fluid {
  position: relative;
  display: block;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 15px;
}
.ant-card-cover {
  /* padding: 10px; */
}
/* Button */
.ButtonHome {
  display: flex;
  justify-content: center;
  align-items: center;
}
.inputSearchHome .anticon.anticon-close-circle {
  color: #f26f6f;
}
.ButtonHome .ButtonHomeBg {
  padding: 10px 30px;
  background-color: #ff505093;
  border-radius: 10px;
  color: rgb(243, 243, 243);
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 7vh;
}
/* Banner */
.contentStyle {
  position: relative;
  height: 430px;
  width: 100%;
  /* margin-top: 50px; */
}
.ant-carousel .slick-slider .slick-dots-top {
  top: 100px !important;
  bottom: auto;
}
.contentStyle .contentImageStyle {
  overflow: hidden;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
  -webkit-filter: brightness(75%);
  filter: brightness(75%);
}
.buttonImageCarousel {
  width: 100%;
  position: absolute;
  text-align: center;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
}
.buttonImageCarouselRegister:hover {
  color: #000;
  border-radius: 8px;
  transition: all 0.6s;
  letter-spacing: 2px;
  font-size: 13px;
  background-color: #c9c2c2;
  cursor: pointer;
}
.buttonImageCarouselRegister {
  background-color: #ededed;
  color: #000;
  border-radius: 8px;
  padding: 13px 30px !important;
  transition: all 0.6s;
  letter-spacing: 2px;
  font-size: 13px;
  cursor: pointer;
}
/* Card */
.cardEvent {
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}
.imgCardZoom {
  overflow: hidden;
  border-radius: 8px 8px 0 0;
}
.imgCardZoom img {
  transition: transform 00.5s ease;
}

.imgCardZoom:hover img {
  transform: scale(1.5);
}

/* input */

.inputSearchHome .css-3nwfqy {
  font-size: 15px;
  color: rgb(196, 196, 196);
  padding-top: 18px;
  line-height: 20px;
}

.inputSearchHome .card_input {
  margin-top: -100px;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}
.inputSearchHome {
  text-align: left;
}

.inputSearchHome .ant-input-affix-wrapper {
  font-size: 18px;
  line-height: 30px;
}

.inputSearchHome .ant-input-affix-wrapper .ant-input,
.inputSearchHome .ant-input-affix-wrapper .ant-input-clear-icon {
  font-size: 18px;
}
.inputSearchHome .ant-input-prefix,
.ant-input-suffix {
  opacity: 0.3;
}

.inputSearchHome .css-1qk4lil-control {
  line-height: 19px;
}
.inputSearchHome .css-1jmwe9z-ValueContainer {
  line-height: 15px;
}

.inputSearchHome .css-z3wjjs-Placeholder {
  font-size: 12px;
}
/* spinn */
.spinner {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
  text-align: center;

  -webkit-animation: sk-rotate 2s infinite linear;
  animation: sk-rotate 2s infinite linear;
}

.dot1,
.dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #333;
  border-radius: 100%;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
.pagination_home {
  text-align: center;
  margin: 20px 0;
}

.pagination_home .ant-pagination-prev {
  min-width: 50px;
  height: 50px;
}
.pagination_home .ant-pagination-item {
  min-width: 50px;
  line-height: 50px;
  height: 50px;
}
.pagination_home .ant-pagination-next {
  min-width: 50px;
  height: 50px;
}

/* Footer */

.footerHomePage {
  margin-top: 50px;
  padding-top: 35px;
  padding-bottom: 5px;
  background-color: #001529;
  text-align: center;
}
.footerHomePage .footerText {
  color: rgb(223, 223, 223);
  font-size: 25px;
}

@media (max-width: 640px) {
  .cardEvent {
    width: 300px;
  }

  .inputSearchHome .css-3nwfqy {
    font-size: 10px;
    color: rgb(196, 196, 196);
    padding-top: 18px;
    line-height: 10px;
  }

  .inputSearchHome .card_input {
    margin-top: -100px;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  }

  .inputSearchHome .ant-input-affix-wrapper {
    font-size: 15px;
    line-height: 20px;
  }

  .inputSearchHome .ant-input-affix-wrapper .ant-input,
  .inputSearchHome .ant-input-affix-wrapper .ant-input-clear-icon {
    font-size: 12px;
  }
  .inputSearchHome .ant-input-prefix,
  .ant-input-suffix {
    opacity: 0.3;
  }

  .inputSearchHome .css-1qk4lil-control {
    line-height: 10px;
  }
  .inputSearchHome .css-1jmwe9z-ValueContainer {
    line-height: 10px;
  }

  .inputSearchHome .css-z3wjjs-Placeholder {
    font-size: 10px;
  }
  .buttonImageCarouselRegister {
    background-color: #ededed;
    color: #000;
    border-radius: 8px;
    padding: 6px 15px !important;
    transition: all 0.6s;
    letter-spacing: 2px;
    font-size: 10px;
    cursor: pointer;
  }
  .contentStyle {
    height: 80vh;
    width: 100vw;
  }
}
