//ref: https://www.freecodecamp.org/news/css-media-queries-breakpoints-media-types-standard-resolutions-and-more/
@extraLarge: ~'only screen and (min-width: 1201px)';
@desktop: ~'only screen and (min-width: 1025px) and (max-width: 1200px)';
@smallDesktop: ~'only screen and (min-width: 769px) and (max-width: 1024px)';
@tablet: ~'only screen and (min-width: 481px) and (max-width: 768px)';
@mobile: ~'only screen and (min-width: 320px) and (max-width: 480px)';

@sidebar-color: #1B1B1B;
// @sidebar-color: #3f4cd9;

body {
  font-family: 'Lato', sans-serif !important;
}

* {
  font-family: 'Lato', sans-serif !important;
}

pre {
  margin-bottom: 0em !important;
  font-family: 'Lato', sans-serif !important;
}

.ant-form-item-label {
  padding: 0 0 0 0 !important;
  label {
    font-weight: 500;
    color: #777;
  }
}

// .ant-table-thead > tr > th {
//   background: #1b1b1b !important;
//   color: #fff !important;
//   font-weight: 600 !important;
// }

.ant-menu-horizontal {
  border-bottom: unset;
}

@media (prefers-color-scheme: dark) {
  .rdg {
    --rdg-font-size: 12px !important;
  }
  .rdg:not(.rdg-light) {
    --color: #ddd;
    --border-color: #444;
    --summary-border-color: #555;
    --background-color: #222;
    --header-background-color: #1c1c1c;
    --row-hover-background-color: #181818;
    --row-selected-background-color: #1a73bc;
    --row-selected-hover-background-color: #1868aa;
    --checkbox-color: #95cfff;
    --checkbox-focus-color: #c8e6ff;
    --checkbox-disabled-border-color: #000;
    --checkbox-disabled-background-color: #333;
  }
}

.main-app-wrapper {
  #page-toolbar-content .page-toolbar-content-wrapper {
    box-shadow: none !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  &.main-app-wrapper-collapsed {
    #page-toolbar-content .page-toolbar-content-wrapper {
      width: calc(100% - 80px) !important;
    }
  }

  &.main-app-wrapper-no-collapsed {
    #page-toolbar-content .page-toolbar-content-wrapper {
      width: calc(100% - 200px) !important;
    }
  }

  .main-app-header {
    display: flex;
    position: fixed;
    width: 100%;
    z-index: 99;
    background: #fff;
    // background: #071e59;
    // color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &.collapsed {
      width: calc(100% - 80px);
    }

    &.no-collapsed {
      width: calc(100% - 250px);
    }
  }

  .main-app-sidebar {
    // margin-top: 65px;
    // padding-top: 15px;
    // height: calc(100vh - 65px);
    // background: #1360ef;
    background-color: @sidebar-color;
    height: 100vh;
    position: fixed;
    border-right: 1px solid rgba(0, 0, 0, 0.1);

    .ant-menu-dark .ant-menu-inline.ant-menu-sub {
      // background: #000c17;
      // background: rgba(0, 21, 41, 0.1) !important;
      // background: rgba(255, 255, 255, 0.1);
    }

    .ant-menu.ant-menu-dark,
    .ant-menu-dark .ant-menu-sub,
    .ant-menu.ant-menu-dark .ant-menu-sub {
      // background: #1360ef;
      background-color: @sidebar-color;
    }

    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
      // background-color: #fa8c15;
    }

    .ant-menu-dark.ant-menu-inline .ant-menu-item,
    .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
      color: #fff;
    }

    .logo {
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      background: rgba(0, 0, 0, 0.2);
    }

    .main-app-sidebar-footer {
      position: fixed;
      bottom: 0;
      background: #000c17;

      &.sidebar-footer-collapsed {
        width: 80px;
      }

      &.sidebar-footer-no-collapsed {
        width: 250px;
      }
    }
  }
  .navbar-right-content {
    display: flex;
    align-items: center;
    margin-left: auto !important;

    .navbar-right-item {
      margin-left: 25px;
      display: flex;
      align-items: center;

      .custom-icon-item {
        font-size: 14px;
      }

      // .custom-avatar {
      //   border: 1px solid @base-border-color !important;
      // }
    }
  }
}

@media print {
  .main-app-wrapper {
    .main-app-header {
      display: none !important;
    }
    .main-app-sidebar {
      width: 0 !important;
      display: none !important;
    }
    &.main-app-wrapper-collapsed {
      width: 100% !important;
    }
    &.main-app-wrapper-no-collapsed {
      width: 100% !important;
    }
    .content-page-wrapper {
      padding: 0 !important;
      margin-top: -20px !important;
      .ant-card {
        border: none !important;
        .ant-card-body {
          padding: 0 !important;
        }
      }
    }
  }
  .ant-tabs-nav {
    display: none !important;
  }
  .ant-layout {
    padding: 0 !important;
  }
}

.footer-table {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: #fff;
}

.react-select-custom-prefix-radius__control {
  border-radius: 4px !important;
  padding: 0 !important;
  margin: 0;
  // &:hover {
  //   border-color: @base-border-color-form !important; // FIXME sesuaikan color dengan component antd
  // }
}

.react-select-custom-prefix-radius__value-container {
  display: flex !important;
}

#event-detail {
  color: #222;
  ul,
  ol {
    padding-left: 20px;
  }
  background: #fff;
  border-radius: 0 0 8px 8px;
  .hero-image {
    position: relative;
    overflow: hidden;
    height: 450px;
    .title {
      width: 100%;
      padding: 10px 20px;
      position: absolute;
      bottom: 0;
      opacity: 0.8;
      background: #222;
      font-size: 1.2rem;
      h1 {
        opacity: 1;
        margin: 0;
        padding: 0;
        color: #fff;
      }
    }
  }
  .event-detail-body {
    padding: 20px;
  }
}

.antd-data-table .ant-table.ant-table-small .ant-table-footer,
.antd-data-table .ant-table.ant-table-small .ant-table-tbody > tr > td,
.antd-data-table .ant-table.ant-table-small .ant-table-thead > tr > th,
.antd-data-table .ant-table.ant-table-small .ant-table-title,
.antd-data-table .ant-table.ant-table-small tfoot > tr > td,
.antd-data-table .ant-table.ant-table-small tfoot > tr > th {
  padding: 8px 8px;
}

div.sticky-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  font-size: 20px;
  z-index: 999;
}

div.sticky-footer {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  font-size: 20px;
  z-index: 9;
}

.wrapper-landing-page {
  padding-left: 7%;
  padding-right: 7%;
}

.content-detail-width {
  width: 1200px;
  // padding-top: 40px;
}

@media @extraLarge {
  .content-detail-width {
    width: 1200px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  // .wrapper-landing-page {
  //   padding-left: 9% !important;
  //   padding-right: 9% !important;
  // }
}
@media @desktop {
  .content-detail-width {
    padding-top: 0;
    width: 90% !important;
  }

  .wrapper-landing-page {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media @smallDesktop {
  .content-detail-width {
    padding-top: 0;
    width: 100% !important;
  }
  .wrapper-landing-page {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media @tablet {
  .content-detail-width {
    padding-top: 0;
    width: 100% !important;
  }
  .wrapper-landing-page {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
@media @mobile {
  .content-detail-width {
    padding-top: 0;
    width: 100% !important;
  }
  .wrapper-landing-page {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

.main-app-wrapper.main-app-wrapper-no-collapsed #page-toolbar-content .page-toolbar-content-wrapper {
  width: calc(100% - 250px) !important;
}

.main-app-wrapper-collapsed {
  .ant-menu-item-group-title {
    display: none !important;
  }
}

.custom-upload-antd {
  .ant-upload {
    width: auto !important;
    min-width: 300px;
    height: auto !important;
    min-height: 150px;
    .ant-image-img {
      border-radius: 4px;
      max-width: 100% !important;
      max-height: 100% !important;
    }
  }
}
